import styled, { css } from 'styled-components';
import LogoAzulImage from 'assets/logo-azul.png'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1170px;
  height: auto;
  background: #FFF;
  -webkit-print-color-adjust: exact;
  margin: 0 auto;

  ${({ minHeight }) =>
    minHeight !== undefined &&
    css`
        min-height: ${minHeight + 'px'} ;
      `
  }

  ${({ minHeight }) =>
    minHeight === undefined &&
    css`
          min-height: auto;
        `
  }

  h1, h2, h3, h4, h5, h6, p, span, b {
    font-family: 'Poppins', sans-serif !important;
  }

`

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: ${props => props.padding !== undefined ? props.padding : 0};
  height: ${props => props.height !== undefined ? props.height : 'auto'};

  > div.half {
    width: 50%;
  }

  > div.d-40w {
    width: 38%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  > div.d-60w {
    width: 62%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-title {
    height: auto;
    min-height: 80px;

    small {
      font-size: 12px !important;
      font-style: italic;
    }
  }

  .p-smaller {
    font-size: 19px;
    line-height: 26px;
    font-weight: 600;
    margin-top: 58px;
  }

  .p-smaller-no-mg {
    font-size: 19px;
    line-height: 26px;
    font-weight: 600;
    margin-top: 19px;
  }

  .half-content {
    height: 100%;
    min-height: 300px;
  }

  .full-content {
    height: 460px;
    width: 100%;
  }

  &.split {
    gap: 6px;
  }

  &.split-2 {
    margin-top: auto;
    gap: 4px;
  }

  .d-purple {
    display: inline-block;
    font-size: 19px;
    padding: 4px 15px;
    font-weight: bold;
    text-align: center;
    background: #5560ff;
    color: #FFFFFF;
    width: fit-content;
  }
`;

export const PoteContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  div.pote-atual-title {
    width: 68%;
    display: flex;
    flex-direction: column;
    border-left: solid 15px #5560ff;
    padding-left: 18px;

    h2 {
      color: #5560ff;
      font-family: 'Poppins', sans-serif !important;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
    }

    div.lista {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      .list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:last-child {
          span.value {
            font-size: 20px;
            border-top: solid 1px #dcddde;
          }
        }

        span.title {
          font-size: 19px;
          font-weight: bold;
          color: #0f0f0f;
        }

        span.value {
          font-size: 19px;
          font-weight: bold;
        }
      }
    }
  }

  div.pote-atual-bar {
    width: 32%;
    display: flex;
    flex-direction: column;
  }

`

export const PurpleBar = styled.div`
  width: 378px;
  height: 40px;
  background: #5560ff;


  &.full-height {
    background: #6b87ff;
    height: 100%;
    padding: 98px 38px 0 38px;
  }

  color: #FFFFFF;

  h1 {
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }

  h2 {
    font-size: 19px;
    margin-bottom: 0;
    margin-top: 20px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }

  p {
    font-size: 19px;
    font-weight: 100;
  }
`

export const Title = styled.h1`
  text-transform: uppercase;
  font-size: 58px;
  font-weight: 800;
  line-height: 66px;
  margin-bottom: 0;
  padding: 0;
  margin-top: -2px;
`

export const SubTitle = styled.h2`
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 0;
  padding: 0;

  &.bigger {
    font-size: 40px;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  sup {
    font-size: 20px;
  }
`

export const Labels = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`

export const Label = styled.li`
  display: inline-flex;
  align-items: center;
  width: 50%;
  gap: 14px;
  height: 30px;
  svg {
    width: 20px;
    height: auto;

    ${({ rotateSvg }) =>
    rotateSvg &&
    css`
        transform: rotate(22.5deg);
      `}

    path {
      color: #5560ff;
    }
  }
`
export const LogoAzul = styled.div`
  width: 60px;
  height: 54px;
  background: url(${LogoAzulImage});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 15px;
`

export const Footer = styled.div`
  width: 90%;
  height: 130px;
  margin: auto;
  border-top: solid 6px #2d2d2d;

  > div {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 23px;
    font-weight: 600;
  }
`



